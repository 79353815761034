import Joi from "joi";
import errorMessages from "./errorMessages.json";

export default Joi.object({
  name: Joi.string()
    .max(50)
    .required(),
  does_allow_free_listing: Joi.allow(0, 1, true, false)
})
  .unknown()
  .messages(errorMessages);
