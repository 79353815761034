<template>
  <div class="admin-salesChannels">
    <portal to="page-call-to-action">
      <ebp-button @click="showAddSalesChannel = true">{{
        $t("add-sales-channel")
      }}</ebp-button>
    </portal>
    <advanced-data-table
      action="salesChannels/paginate"
      :headers="headers"
      ref="table"
      :filters.sync="filters"
      :limit="50"
    >
      <template #cell(parent_channel)="{ item }">{{
        item.parent_channel ? item.parent_channel.name : ""
      }}</template>
      <template #cell(does_allow_free_listing)="{ item }">{{
        $t(item.does_allow_free_listing ? "yes" : "no")
      }}</template>
      <template #cell(does_allow_promos)="{ item }">{{
        $t(item.does_allow_promos ? "yes" : "no")
      }}</template>
      <template #cell(category)="{ item }">{{ $t(item.category) }}</template>
      <!-- Actions -->
      <template #actions="{ item }">
        <b-button
          v-tooltip.top-center="$t('edit')"
          variant="primary"
          size="sm"
          @click="edit(item)"
        >
          <i class="bx bx-pencil"></i>
        </b-button>
        <b-button
          v-tooltip.top-center="$t('delete')"
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          @click="remove(item)"
        >
          <i class="bx bxs-trash"></i>
        </b-button>
      </template>

      <!-- Custom filters -->
      <template #filters>
        <!-- Category filter -->
        <adt-filter :label="$t('category')">
          <b-form-select
            :options="addAllOption(categories)"
            v-model="filters.category"
            size="sm"
          ></b-form-select>
        </adt-filter>

        <!-- Allows Promos filter -->
        <adt-filter :label="$t('allows-promos')">
          <b-form-select
            :options="addAllOption(promo_options)"
            v-model="filters.does_allow_promos"
            size="sm"
          ></b-form-select>
        </adt-filter>

        <!-- Free listing filter -->
        <adt-filter :label="$t('allows-free-listing')">
          <b-form-select
            :options="addAllOption(free_listing_options)"
            v-model="filters.does_allow_free_listing"
            size="sm"
          ></b-form-select>
        </adt-filter>

        <!-- Has parent filer -->
        <adt-filter :label="$t('parents-only')">
          <b-form-select
            :options="yesNo"
            v-model="filters.parent_only"
            size="sm"
          ></b-form-select>
        </adt-filter>
      </template>
    </advanced-data-table>

    <!-- SalesChannel edit modal -->
    <ebp-modal :open.sync="open" :tabs="editTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #edit-sales-channel>
        <EditSalesChannel :channel="editedItem" @added="open = false" />
      </template>
    </ebp-modal>

    <!-- SalesChannel add modal -->
    <ebp-modal :open.sync="showAddSalesChannel" :tabs="addTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #add-sales-channel>
        <AddSalesChannel @added="added" />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import salesChannelSchema from "@/validation-schemas/salesChannel";

import AddSalesChannel from "@/views/admin/system/sales-channels/Add";
import EditSalesChannel from "@/views/admin/system/sales-channels/Edit";

import AdtFilter from "@/components/core/adt-filter";

import SalesChannelCategories from "@/fixtures/sales-channel-categories";

export default {
  name: "admin-sales-channels",
  components: {
    AdvancedDataTable,
    AddSalesChannel,
    AdtFilter,
    EditSalesChannel
  },
  data() {
    return {
      headers: [
        "name",
        {
          key: "does_allow_free_listing",
          label: "allows-free-listing"
        },
        {
          key: "does_allow_promos",
          label: "allows-promos"
        },
        "category",
        {
          key: "parent_channel",
          label: "parent-channel",
          sortable: false
        }
      ],
      editedItem: null,
      saving: false,
      open: false,
      showAddSalesChannel: false,
      error: null,
      editTabs: ["edit-sales-channel"],
      addTabs: ["add-sales-channel"],
      categories: SalesChannelCategories.map(type => ({
        text: this.$t(type),
        value: type
      })),
      free_listing_options: ["yes", "no"].map(type => ({
        text: this.$t(type),
        value: type === "yes" ? 1 : 0
      })),
      promo_options: ["yes", "no"].map(type => ({
        text: this.$t(type),
        value: type === "yes" ? 1 : 0
      })),
      filters: {
        category: null,
        does_allow_free_listing: null,
        does_allow_promos: null,
        parent_only: false
      }
    };
  },
  mounted() {
    this.$store.dispatch("ancillaries/getSalesChannels");
  },
  methods: {
    added() {
      this.showAddSalesChannel = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async remove(salesChannel) {
      if (confirm(this.$t("remove-message", { name: salesChannel.name }))) {
        try {
          await this.$store.dispatch("salesChannels/delete", salesChannel.id);
          this.$refs.table.change("id", salesChannel.id);
        } catch (err) {
          console.error(err);
        }
      }
    },
    edit(salesChannel) {
      this.editedItem = salesChannel;
      this.open = true;
    },
    async save() {
      this.error = null;
      const salesChannel = this.editedItem;
      const validation = salesChannelSchema.validate(salesChannel);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("salesChannels/update", salesChannel);
          this.$refs.table.change("id", salesChannel.id, salesChannel);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  }
};
</script>

<style></style>
