<template>
  <div class="admin-edit-salesChannel">
    <error :err.sync="error" />
    <form-generator
      :elements="elements"
      :data="channel || {}"
      :handleUpdate="({ key }, v) => (channel[key] = v)"
    />
  </div>
</template>

<script>
import salesChannelSchema from "@/validation-schemas/salesChannel";
import FormGenerator from "@/components/form-generator.vue";
import SalesChannelsCategories from "@/fixtures/sales-channel-categories";
import { mapState } from "vuex";

export default {
  components: { FormGenerator },
  name: "admin-edit-sales-channel",
  props: {
    channel: Object
  },
  data() {
    return {
      error: null,
      loading: false
    };
  },
  computed: {
    ...mapState("ancillaries", ["salesChannels"]),
    elements() {
      return [
        {
          key: "name",
          label: "name",
          type: "string"
        },
        {
          key: "category",
          label: "category",
          type: "select",
          attrs: {
            options: SalesChannelsCategories.map(s => ({
              text: this.$t(s),
              value: s
            }))
          }
        },
        {
          key: "parent_channel",
          label: "parent-channel",
          type: "multiselect",
          attrs: {
            trackBy: "id",
            label: "name",
            options: this.salesChannels,
            loading: !this.salesChannels.length,
            multiple: false
          }
        },
        {
          key: "does_allow_free_listing",
          label: "allows-free-listing",
          type: "switch"
        },
        {
          key: "does_allow_promos",
          label: "allows-promos",
          type: "switch"
        },
        {
          text: "save",
          type: "button",
          className: "float-right mt-5 mt-sm-0",
          attrs: {
            loading: this.loading,
            block: this.isSmallScreen
          },
          events: {
            click: this.add
          }
        }
      ];
    }
  },
  methods: {
    async add() {
      this.error = null;
      const { channel } = this;
      const validation = salesChannelSchema.validate(channel, this.joiOptions);
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          const res = await this.$store.dispatch("salesChannels/update", {
            ...channel,
            parent_channel_id: channel.parent_channel
              ? channel.parent_channel.id
              : null
          });
          this.$store.dispatch("ancillaries/getSalesChannels", {
            wasUpdated: true
          });
          this.$emit("added", res.data);
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
